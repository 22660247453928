import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
//import { GlobalStyle, ContentWrapper } from "Containers/Ride/ride.style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";
import { DrawerProvider } from "common/contexts/DrawerContext";
//import Navbar from "Containers/Ride/Navbar";
import Navbar from "Containers/MainNavbar";
import BannerSection from "Containers/Banner";
import FeatureSection from "Containers/Feature";
import FeatureSlider from "Containers/FeatureSlider";
import ProductSlide from "Containers/ProductSlide";
import Newsletter from "Containers/Newsletter";
//import Footer from "Containers/Ride/Footer";
import Footer from "Containers/Footer";
import Seo from "components/seo";
import { Modal } from "@redq/reuse-modal";
import PartnerSection from "Containers/Partner";
import FaqSection from "Containers/Faq";
import ScrollItems from "../Containers/ScrollItems";
import { graphql, StaticQuery } from "gatsby";
import mainImg from "../images/main.png";
import prt1Img from "../images/partner1.png";
import prt2Img from "../images/partner2.png";
import prt3Img from "../images/partner3.png";
import prt4Img from "../images/partner4.png";
import prt5Img from "../images/partner5.png";
import prt6Img from "../images/partner6.png";
import prt7Img from "../images/partner7.png";
import { Link } from "gatsby";
import Button from "common/components/Button";

const IndexPageEn = () => {
  const [Tours, setTours] = React.useState();
  const [lang, setLang] = React.useState("En");

  const textLang = {
    toursSlogan: { En: "Our Tours", He: "הסיורים שלנו" },
    toursTitle: { En: "Choose your tour", He: "בחרו את הסיור שלכם" },
  };
  const videoObj = {
    url: "https://www.youtube.com/embed/YeY_KgZeqSI",
    poster_url: mainImg,
  };

  const allPartnersImg = [
    { url: prt1Img, title: "ItravleJRS" },
    { url: prt2Img, title: "first station" },
    { url: prt3Img, title: "brown hotels" },
    { url: prt4Img, title: "eldan" },
    { url: prt5Img, title: "traveling jerusalen" },
    { url: prt6Img, title: "grafitiyul" },
    { url: prt7Img, title: "eldan hotel" },
  ];

  const allTour = [
    "7Oo4lNT5mYbIGk5mty1L",
    "CTQTBUBccHjhEPqtqjTb",
    "F7cCMatDzNcBGKYt9ZJU",
    "FuVaHdIgKjP2nYRvB9mo",
    "MCEwhQmMu9NZAQtv6jif",
    /* "Os7ptUBAYnNo6sDAgY8v", */
    "QzmZA14cPQC9bNVp1MyU",
    "aDdt2g6RFxeheYkqzMGj",
    "d5oxDdgraYCiqEf3uYyJ",
    "fZpUFiTLR1Zmmcr5kzKT",
    "ffv7KWJnSrt9u9zm1MYY",
    "nRc0poh1KwVwPOtKt4R6",
    "xVh6VucQIhm7GvZVtb41",
    "zXWLehWWGeUZs1dvmwTE",
  ];

  return (
    <StaticQuery
      query={graphql`
        query allToursMainPageEnQuery {
          allTour {
            nodes {
              accessible
              all_media {
                name
                order
                poster_url
                profile
                type
                url
                poster_name
              }
              audience
              description
              distance
              duration
              equipment
              hours_range {
                max
                min
              }
              id
              kosher
              location
              main_sentense
              price
              rating {
                total
                votes
              }
              title
              tour_guide {
                email
                full_name
                id
                profile_image
              }
              type
              hostedBy
              tour_image_obj {
                title
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        setTours(data.allTour.nodes);

        if (!Tours) return null;

        const toursToDesplay = Tours.filter(function (tour) {
          return allTour.includes(tour.id);
        });

        return (
          <ThemeProvider theme={saasClassicTheme}>
            <Fragment>
              <Seo
                title="Dguide | Choose a destination, Tour it your way"
                description={
                  "Self guided tours in your own way and your own pace. choose tour guide and get a tour whenever you want."
                }
                lang={lang}
                meta={[
                  "סיורים",
                  "סיורים בירושלים",
                  "טיול בירושלים",
                  "טיול",
                  "טיול למשפחה",
                  "מקומות לטייל בשבת",
                  "איפה אפשר לטייל",
                  "המלצות לטיולים",
                  "טיול יום",
                  "סיור יום",
                  "אטרקציות בירושלים",
                  "סיור עצמאי",
                  "מדריך טיולים",
                  "יום כיף",
                  "מה לעשות בירושלים",
                  "סיורים מודרכים בירושלים",
                ]}
                keywords={[
                  "סיור מודרך",
                  "סיור עצמאי",
                  `מה אפשר לעשות בירושלים`,
                  `מה אפשר לעשות תל אביב`,
                  "טיולים",
                ]}
              />
              <Modal />
              <ResetCSS />
              <GlobalStyle />
              <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  <DrawerProvider>
                    <Navbar lang={lang} setLang={setLang} />
                  </DrawerProvider>
                </Sticky>
                <BannerSection
                  tourTrail={videoObj}
                  indexPage={true}
                  lang={lang}
                />
                <FeatureSection lang={lang} />
                <FeatureSlider lang={lang} />
                {Tours && (
                  <ScrollItems
                    tours={toursToDesplay}
                    lang={lang}
                    title={textLang["toursTitle"][lang]}
                    slogan={textLang["toursSlogan"][lang]}
                  />
                )}
                <div className="alltour_btn">
                  <Link href={`/AllToursEn`}>
                    <Button
                      className="alltour_btn"
                      title={"Watch all of our tours"}
                    />
                  </Link>
                </div>
                <PartnerSection lang={lang} />
                {<ProductSlide allPics={allPartnersImg} indexPage={true} />}
                <FaqSection lang={lang} />
                <Newsletter lang={lang} />
                <Footer lang={lang} />
              </ContentWrapper>
            </Fragment>
          </ThemeProvider>
        );
      }}
    />
  );
};
export default IndexPageEn;
